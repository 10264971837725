import React, { useContext, useEffect } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap';
import { IMLocalized } from '../../../language/IMLocalized';
import { NewMainContext } from '../../../context/NewMainContext';
import { useDispatch } from 'react-redux';
import { user_self } from '../../../action/user/user_self';
import { dropdown_list } from '../../../action/dropdown_list';
import { get_claim_list } from '../../../action/claim/get_claim_list';
import { get_leave_list } from '../../../action/get_leave_list';
import { user_get_available_leave } from '../../../action/user/user_get_available_leave';
import { get_medical_claim_autocomplete } from '../../../action/claim/get_medical_claim_autocomplete';
import { user_get_available_claim } from '../../../action/user/user_get_available_claim';
import { get_general_dashboard } from '../../../action/dashboard/get_general_dashboard';
import { general_daterange } from '../../../action/general_daterange';
import { get_employee_list } from '../../../action/employee/get_employee_list';
import { user_leave_all_apply_detail } from '../../../action/user/user_leave_all_apply_detail';
import { get_user_manager_list } from '../../../action/approvals/get_user_manager_list';
import { get_contract_template_dropdown } from '../../../action/get_contract_template_dropdown';
import { getScheduleList } from '../../../action/schedule_list';
import { get_employee_delegation_list } from '../../../action/employee/get_employee_delegation_list';
import { get_self_payroll } from '../../../action/user/get_self_payroll';
import { get_otp_list } from '../../../action/user/get_otp_list';
import { get_otp_initial } from '../../../action/user/get_otp_initial';
import { user_apply_claim_list } from '../../../action/user/user_apply_claim_list';
import { user_apply_leave_list } from '../../../action/user/user_apply_leave_list';
import { user_get_all_apply_leave_list } from '../../../action/user/user_get_all_apply_leave_list';
import { get_general_color } from '../../../action/get_general_color';
import { user_get_all_leave_list } from '../../../action/user/user_get_all_leave_list';
import { get_leave_pending_color } from '../../../action/user/get_leave_pending_color';
import { user_holiday_list } from '../../../action/user/user_holiday_list';
import { get_approvals_claim_list } from '../../../action/claim/get_approvals_claim_list';
import { get_task_list } from '../../../action/task/get_task_list';
import { manager_leave_list } from '../../../action/leave/manager_leave_list';
import { get_manager_appraisal_list } from '../../../action/appraisal/get_manager_appraisal_list';
import { get_appraisal_form_list } from '../../../action/appraisal/get_appraisal_form_list';
import { get_apply_leave_list } from '../../../action/get_apply_leave_list';

export default function UserNavbar(){

    const {current_user, addItem, currentnavs } = useContext(NewMainContext);
    const dispatch = useDispatch();

    useEffect(()=>{
        let mounted = true;
        function idExists(arr){
            let count = 0;
            for(let i =0;i<arr.length;i++){
                const bool = currentnavs.some(item=>item.id === arr[i]);
                if(bool === true){
                    count++;
                }
            }
            return count > 0 ? true : false;
        }

        if(mounted){
            if(currentnavs.length !== 0){
                dispatch(user_self());
                dispatch(dropdown_list());
                dispatch(get_claim_list());
                dispatch(get_leave_list());
                dispatch(user_get_available_leave());
                dispatch(get_medical_claim_autocomplete());
                dispatch(user_get_available_claim());
                dispatch(get_general_dashboard());
                dispatch(general_daterange());

                if(idExists(['5'])){
                    dispatch(get_employee_list());
                    dispatch(user_leave_all_apply_detail());
                    dispatch(user_get_all_apply_leave_list());
                    dispatch(user_holiday_list());
                }

                if(idExists(['2'])){
                    dispatch(get_user_manager_list());
                    dispatch(get_contract_template_dropdown());
                    dispatch(getScheduleList());
                    dispatch(get_employee_delegation_list());
                    dispatch(get_self_payroll());
                    dispatch(get_otp_list());
                    dispatch(get_otp_initial());
                }
                
                if(idExists(['2','3'])){
                    dispatch(user_apply_claim_list());
                }

                if(idExists(['2','4'])){
                    dispatch(user_apply_leave_list());
                }

                if(idExists(['2','3','4','5','6','7','9'])){
                    dispatch(get_general_color());
                }

                if(idExists(['5','6'])){
                    dispatch(user_get_all_leave_list());
                    dispatch(get_leave_pending_color());
                }

                if(idExists(['7'])){
                    dispatch(get_approvals_claim_list());
                }

                if(idExists(['8'])){
                    dispatch(get_task_list());
                }

                if(idExists(['9'])){
                    dispatch(manager_leave_list());
                }

                if(idExists(['10'])){
                    dispatch(get_manager_appraisal_list());
                    dispatch(get_appraisal_form_list());
                }
                if(idExists(['7','24','25','26'])){
                    dispatch(manager_leave_list());
                    dispatch(get_apply_leave_list());
                    dispatch(get_leave_pending_color());
                    dispatch(get_general_color());
                }
                
            }
        }

        return ()=> mounted = false;
    },[currentnavs,dispatch])

    return(
        <Nav navbar>
            <NavItem className='cursor-pointer'>
                <NavLink className='text-white font-text' onClick={()=>addItem('1','dashboard')}><span className="font-text">{IMLocalized('dashboard')}</span></NavLink>
            </NavItem>
            <NavItem className='cursor-pointer'>
                <NavLink className='text-white font-text' onClick={()=>addItem('2','profile')}><span className="font-text">{IMLocalized('profile')}</span></NavLink>
            </NavItem>
            {process.env.REACT_APP_LEAVE === 'true' &&
            <UncontrolledDropdown nav inNavbar className='yamm-fw'>
                <DropdownToggle nav caret className='text-white font-text'>
                    <span className='font-text'>{IMLocalized('leave')}</span>
                </DropdownToggle>
                <DropdownMenu className='scrollable-menu' right>
                    <div className='yamm-content'>
                        <div className='row'>
                            <ul className='col-lg-3 list-unstyled bor-right'>
                                <li className='pl-3'>
                                    <h4 className="title-color1">{IMLocalized('leave_view')}</h4>
                                </li>
                                <hr className='m-1' />
                                {current_user === 'manager' &&
                                // <DropdownItem className="cursor-pointer" onClick={()=>addItem('9','leave_calendar1')}>{IMLocalized('leave_calendar1')} </DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('24','leave_calendar')}>{IMLocalized('leave_calendar')} </DropdownItem>}
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('5','self_leave')}>{IMLocalized('self_leave')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('4','leave_table')}>{IMLocalized('leave_table')}</DropdownItem>
                            </ul>
                        </div>
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>}
            {process.env.REACT_APP_CLAIM === 'true' &&
            <UncontrolledDropdown nav inNavbar className="yamm-fw">
                <DropdownToggle nav caret className="text-white font-text">
                    <span className="font-text">{IMLocalized('claim')}</span>
                </DropdownToggle>
                <DropdownMenu className="scrollable-menu" right>
                    <div className="yamm-content">
                        <div className="row">
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('claim')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('3','claim_list')} >{IMLocalized('claim_list')}</DropdownItem>
                                {current_user === 'manager' &&
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('7','claim_approval_self')}>{IMLocalized('claim_approval_self')}</DropdownItem>}                                        
                                
                            </ul>
                        </div>
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>}
            {/* {current_user === 'manager' &&
            <NavItem className='cursor-pointer'>
                <NavLink className='text-white font-text' onClick={()=>addItem('10','appraisal')} ><span className="font-text">{IMLocalized('appraisal')}</span></NavLink>
            </NavItem>} */}
        </Nav>
    )
}
