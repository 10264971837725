import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap';
import { get_additional_setting } from '../../../action/additional_setting';
import { get_appraisal_form_list } from '../../../action/appraisal/get_appraisal_form_list';
import { get_appraisal_group_list } from '../../../action/appraisal/get_appraisal_group_list';
import { get_approvals_leave_list } from '../../../action/approvals/get_approvals_leave_list';
import { get_user_manager_list } from '../../../action/approvals/get_user_manager_list';
import { get_apply_claim_list } from '../../../action/claim/get_apply_claim_list';
import { get_approvals_claim_list } from '../../../action/claim/get_approvals_claim_list';
import { get_claim_approval } from '../../../action/claim/get_claim_approval';
import { get_claim_employee_noscheme } from '../../../action/claim/get_claim_employee_noscheme';
import { get_claim_group_template } from '../../../action/claim/get_claim_group_template';
import { get_claim_list } from '../../../action/claim/get_claim_list';
import { get_claim_option } from '../../../action/claim/get_claim_option';
import { get_claim_scheme_list } from '../../../action/claim/get_claim_scheme_list';
import { get_medical_claim_autocomplete } from '../../../action/claim/get_medical_claim_autocomplete';
import { get_pay_summary_list } from '../../../action/claim/get_pay_summary_list';
import { get_company_bank } from '../../../action/company/get_company_bank';
import { getCompanyDropdown } from '../../../action/company_dropdown';
import { get_company_list } from '../../../action/company_list';
import { get_general_dashboard } from '../../../action/dashboard/get_general_dashboard';
import { get_key_events } from '../../../action/dashboard/get_key_events';
import { dropdown_list } from '../../../action/dropdown_list';
import { get_all_employee_available_claim } from '../../../action/employee/get_all_employee_available_claim';
import { get_all_employee_available_leave } from '../../../action/employee/get_all_employee_available_leave';
import { get_employee_address } from '../../../action/employee/get_employee_address';
import { get_employee_bank } from '../../../action/employee/get_employee_bank';
import { get_employee_certification } from '../../../action/employee/get_employee_certification';
import { get_employee_contract_list } from '../../../action/employee/get_employee_contract_list';
import { get_employee_education } from '../../../action/employee/get_employee_education';
import { get_employee_list } from '../../../action/employee/get_employee_list';
import { get_employee_list_withoutcontract } from '../../../action/employee/get_employee_list_withoutcontract';
import { get_employee_maternity_list } from '../../../action/employee/get_employee_maternity_list';
import { get_employee_passport } from '../../../action/employee/get_employee_passport';
import { get_employee_permit } from '../../../action/employee/get_employee_permit';
import { get_employee_picture_list } from '../../../action/employee/get_employee_picture_list';
import { get_employee_quick_view } from '../../../action/employee/get_employee_quick_view';
import { get_employee_relationship } from '../../../action/employee/get_employee_relationship';
import { get_employee_resign_today } from '../../../action/employee/get_employee_resign_today';
import { get_employee_role_list } from '../../../action/employee/get_employee_role_list';
import { get_employee_sg_child } from '../../../action/employee/get_employee_sg_child';
import { get_latest_employee } from '../../../action/employee/get_latest_employee';
import { get_min_resign_date } from '../../../action/employee/get_min_resign_date';
import { general_currency } from '../../../action/general_currency';
import { general_daterange } from '../../../action/general_daterange';
import { get_apply_leave_list } from '../../../action/get_apply_leave_list';
import { get_base_setting } from '../../../action/get_base_setting';
import { get_branch_list } from '../../../action/company/get_branch_list';
import { get_contract_template_dropdown } from '../../../action/get_contract_template_dropdown';
import { get_contract_template_list } from '../../../action/get_contract_template_list';
import { get_employee_probation_list } from '../../../action/get_employee_probation_list';
import { get_general_color } from '../../../action/get_general_color';
import { get_general_letter } from '../../../action/get_general_letter';
import { getHolidayList } from '../../../action/get_holiday_list';
import { get_iras_nationality } from '../../../action/get_iras_nationality';
import { get_leave_list } from '../../../action/get_leave_list';
import { get_leave_option } from '../../../action/get_leave_option';
import { get_leave_scheme_list } from '../../../action/get_leave_scheme_list';
import { get_otp_blacklist } from '../../../action/get_otp_blacklist';
import { get_payroll_period } from '../../../action/get_payroll_period';
import { get_payroll_history_list } from '../../../action/history/get_payroll_history_list';
import { get_iras_list } from '../../../action/iras/get_iras_list';
import { get_leave_approval } from '../../../action/leave/get_leave_approval';
import { get_leave_employee_noscheme } from '../../../action/leave/get_leave_employee_noscheme';
import { get_leave_group_template } from '../../../action/leave/get_leave_group_template';
import { leave_reset_list } from '../../../action/leave/leave_reset_list';
import { manager_leave_list } from '../../../action/leave/manager_leave_list';
import { get_payroll_available_date } from '../../../action/payroll/get_payroll_available_date';
import { get_payroll_increment_list } from '../../../action/payroll/get_payroll_increment_list';
import { get_payroll_list } from '../../../action/payroll/get_payroll_list';
import { get_general_aggrid_template } from '../../../action/report/get_general_aggrid_template';
import { getScheduleDropdown } from '../../../action/schedule_dropdown';
import { getScheduleList } from '../../../action/schedule_list';
import { get_task_list } from '../../../action/task/get_task_list';
import { get_leave_pending_color } from '../../../action/user/get_leave_pending_color';
import { user_get_available_leave } from '../../../action/user/user_get_available_leave';
import { NewMainContext } from '../../../context/NewMainContext';
import { IMLocalized } from '../../../language/IMLocalized';
import ClaimDetailReport from '../../report/ClaimDetailReport';
import ClaimListReport from '../../report/ClaimListReport';
import EmployeeProbationReport from '../../report/EmployeeProbationReport';
import EmployeeRoleReport from '../../report/EmployeeRoleReport';
import LeaveBalanceReport from '../../report/LeaveBalanceReport';
import LeaveListReport from '../../report/LeaveListReport';
import LeaveDetailReport from '../../report/leave_detail_report';
import SummaryReportClaim from '../../report/summary_report_claim';
import SummaryReportLeave from '../../report/summary_report_leave';
import ForeignWorkerHeadCountReport from '../../report/ForeignWorkerHeadCountReport';
import MonthlyHeadCountReport from '../../report/MonthlyHeadCountReport';
import { get_employee_self_help } from '../../../action/employee/get_employee_self_help';
import { get_payroll_additional_setting } from '../../../action/get_payroll_additional_setting';
import { get_skip_option } from '../../../action/employee/get_skip_option';

export default function HrNavbar(){
    
    const {currentnavs, addItem } = useContext(NewMainContext);
    const dispatch = useDispatch();

    useEffect(()=>{
        let is_mounted =true;
        function idExists(arr){
            let count = 0;
            for(let i =0 ;i<arr.length; i++){
                const bool = currentnavs.some(item=>item.id === arr[i]);
                if(bool === true){
                    count++;
                }
            }
            return count > 0 ? true : false; 
        }
        if(is_mounted){
            if(currentnavs.length !== 0){
                dispatch(get_employee_list());
                dispatch(get_payroll_period());
                dispatch(get_leave_list());
                dispatch(get_payroll_list());
                dispatch(dropdown_list());
                dispatch(get_claim_list());
                dispatch(get_all_employee_available_leave());
                dispatch(get_all_employee_available_claim());
                dispatch(get_medical_claim_autocomplete());
                dispatch(general_daterange())
                if(idExists(['1'])){
                    dispatch(get_general_dashboard());
                    dispatch(user_get_available_leave());
                    dispatch(get_key_events());
                    dispatch(get_leave_employee_noscheme());
                    dispatch(get_claim_employee_noscheme());
                    dispatch(get_skip_option());
                }

                if(idExists(['4','5','7','9','20','21','47','73'])){
                    dispatch(get_additional_setting());
                }

                if(idExists(['7','9','15','23','47'])){
                    dispatch(general_currency());
                }

                if(idExists(['2','3','7','9','10','57','60','64','65','77','87','90'])){
                    dispatch(get_company_list());
                }

                if(idExists(['2','3'])){
                    dispatch(getCompanyDropdown());
                    dispatch(get_company_bank());
                }

                if(idExists(['2','7','9','10','57','60','64','65'])){
                    dispatch(get_branch_list());
                }

                if(idExists(['7','9','20','21','47','73'])){
                    dispatch(get_contract_template_dropdown());
                }

                if(idExists(['7','9','11','20','21','47','73'])){
                    dispatch(getScheduleList());
                }

                if(idExists(['46'])){
                    dispatch(manager_leave_list());
                }

                if(idExists(['77'])){
                    dispatch(get_iras_list());
                    dispatch(get_iras_nationality());
                }

                if(idExists(['11','12','24','46'])){
                    dispatch(getHolidayList());
                }

                if(idExists(['9','22','58'])){
                    dispatch(get_base_setting());
                    dispatch(get_general_color());
                }

                if(idExists(['27'])){
                    dispatch(get_leave_option());
                }

                if(idExists(['24','25','26','7','34','46','54'])){
                    dispatch(get_leave_pending_color());
                    dispatch(get_general_color());
                }

                if(idExists(['35'])){
                    dispatch(get_claim_option());
                }

                if(idExists(['7','9','38','57','67','75'])){
                    dispatch(get_claim_scheme_list());
                }

                if(idExists(['7','24','25','26'])){
                    dispatch(get_apply_leave_list());
                }

                if(idExists(['7','34'])){
                    dispatch(get_apply_claim_list());
                }

                if(idExists(['41'])){
                    dispatch(get_pay_summary_list());
                }

                if(idExists(['7','15'])){
                    dispatch(get_employee_picture_list());
                }

                if(idExists(['9'])){
                    dispatch(get_latest_employee());
                }

                if(idExists(['7','44','73'])){
                    dispatch(get_employee_address());
                }

                if(idExists(['73','74'])){
                    dispatch(get_general_aggrid_template());
                }

                if(idExists(['7','9','20','21'])){
                    dispatch(get_contract_template_list());
                }

                if(idExists(['7','9','30','33','32','64','76'])){
                    dispatch(get_leave_scheme_list());
                }
                
                if(idExists(['7','44','1'])){
                    dispatch(get_employee_permit());
                    dispatch(get_employee_passport());
                }

                if(idExists(['7','44','49','79'])){
                    dispatch(get_employee_bank());
                }

                if(idExists(['7','44'])){
                    dispatch(get_employee_sg_child());
                    dispatch(get_employee_maternity_list());
                }

                if(idExists(['15','45'])){
                    dispatch(get_payroll_history_list());
                }

                if(idExists(['46'])){
                    dispatch(get_approvals_leave_list());
                }

                if(idExists(['47'])){
                    dispatch(get_employee_list_withoutcontract());
                }

                if(idExists(['49'])){
                    dispatch(get_employee_role_list());
                }

                if(idExists(['54'])){
                    dispatch(get_approvals_claim_list());
                }

                if(idExists(['57','58','59','62','63'])){
                    dispatch(get_user_manager_list());
                }

                if(idExists(['62','7','65','9','64','71'])){
                    dispatch(get_leave_approval());
                }

                if(idExists(['63','7','9','67','72'])){
                    dispatch(get_claim_approval());
                }

                if(idExists(['7'])){
                    dispatch(get_min_resign_date());
                    dispatch(get_employee_education());
                    dispatch(get_employee_certification());
                    dispatch(get_employee_relationship());
                }
                
                if(idExists(['15'])){
                    dispatch(get_payroll_available_date());
                    dispatch(get_employee_resign_today());
                }

                if(idExists(['8'])){
                    dispatch(get_employee_probation_list());
                }

                if(idExists(['11'])){
                    dispatch(getScheduleDropdown());
                }

                if(idExists(['33'])){
                    dispatch(leave_reset_list());
                }
                
                if(idExists(['44'])){
                    // set is_history = true
                }

                if(idExists(['59','62'])){
                    dispatch(get_leave_group_template());
                }

                if(idExists(['57','58','63'])){
                    dispatch(get_claim_group_template());
                }

                if(idExists(['15','81'])){
                    dispatch(get_general_letter());
                }

                if(idExists(['61'])){
                    dispatch(get_task_list());
                }

                if(idExists(['68','69','70'])){
                    dispatch(get_appraisal_form_list());
                }

                if(idExists(['73'])){
                    dispatch(get_employee_contract_list());
                }

                if(idExists(['69','70'])){
                    dispatch(get_appraisal_group_list());
                }

                if(idExists(['78'])){
                    dispatch(get_otp_blacklist());
                }

                if(idExists(['86'])){
                    dispatch(get_employee_quick_view());
                }

                if(idExists(['88'])){
                    dispatch(get_payroll_increment_list());
                }

                if(idExists(['89'])){
                    dispatch(get_employee_self_help());
                }   
                if(idExists(['90'])){
                    dispatch(get_additional_setting());
                }
                if(idExists(['91'])){
                    dispatch(get_payroll_additional_setting());
                }
            }
        }
        return()=>{
            is_mounted = false;
        }
    },[currentnavs,dispatch,])

    
    
    return(
        <Nav navbar>
            <NavItem className='cursor-pointer'>
                <NavLink className='text-white font-text' onClick={()=>addItem('1','dashboard')}><span className="font-text">{IMLocalized('dashboard')}</span></NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar className='nav-item dropdown yamm-fw'>
                <DropdownToggle nav caret className='text-white font-text'>
                    <span className='font-text'>{IMLocalized('company')}</span>
                </DropdownToggle>
                <DropdownMenu className='scrollable-menu' left="true">
                    <div className='yamm-content'>
                        <div className='row'>
                            <ul className='col-lg-3 list-unstyled bor-right'>
                                <li className='pl-3'>
                                    <h4 className="title-color1">{IMLocalized('company')}</h4>
                                </li>
                                <hr className='m-1' />
                                <DropdownItem className='cursor-pointer' onClick={()=>addItem('2','company_list')}>{IMLocalized('company_list')}</DropdownItem>
                            </ul>
                            <ul className='col-lg-3 list-unstyled bor-right'>
                                <li className='pl-3'>
                                    <h4 className='title-color1'>{IMLocalized('annual_setting')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className='cursor-pointer' onClick={()=>addItem('33','leave_reset')}>{IMLocalized('leave_reset')}</DropdownItem>
                                <DropdownItem className='cursor-pointer' onClick={()=>addItem('77','iras')}>{IMLocalized('iras')}</DropdownItem>
                                <DropdownItem className='cursor-pointer' onClick={()=>addItem('78','otp_blacklist')}>{IMLocalized('otp_blacklist')}</DropdownItem>
                            </ul>
                            <ul className='col-lg-3 list-unstyled bor-right'>
                                <li className='pl-3'>
                                    <h4 className='title-color1'>{IMLocalized('setup')}</h4>
                                </li>
                                <hr className='m-1' />
                                <DropdownItem className='cursor-pointer' onClick={()=>addItem('22','general_setting')}>{IMLocalized('general_setting')}</DropdownItem>
                                <DropdownItem className='cursor-pointer' onClick={()=>addItem('5','overtime_allowance')}>{IMLocalized('overtime_allowance')}</DropdownItem>
                                <DropdownItem className='cursor-pointer' onClick={()=>addItem('4','additional_setting')}>{IMLocalized('additional_setting')}</DropdownItem>
                                <DropdownItem className='cursor-pointer' onClick={()=>addItem('23','foreign_currency')}>{IMLocalized('foreign_currency')}</DropdownItem>
                                <DropdownItem className='cursor-pointer' onClick={()=>addItem('74','custom_report_template')}>{IMLocalized('custom_report_template')}</DropdownItem>
                                {process.env.REACT_APP_PAYROLL === 'true' &&
                                <DropdownItem className='cursor-pointer' onClick={()=>addItem('91','payroll_additional_setting')}>{IMLocalized('payroll_additional_setting')}</DropdownItem>
                                }
                            </ul>
                            <ul className='col-lg-3 list-unstyled bor-right'>
                                <li className='pl-3'>
                                    <h4 className="title-color1">{IMLocalized('basic_setup')}</h4>
                                </li>
                                <hr className='m-1' />
                                <DropdownItem className='cursor-pointer' onClick={()=>addItem('27','leave_option')}>{IMLocalized('leave_option')}</DropdownItem>
                                <DropdownItem className='cursor-pointer' onClick={()=>addItem('35','claim_option')}>{IMLocalized('claim_option')}</DropdownItem>
                                <li className="pl-3 mt-2">
                                    <h4 className="title-color1">{IMLocalized('mass_assign')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className='cursor-pointer' onClick={()=>addItem('59','leave_group_template')}>{IMLocalized('leave_group_template')}</DropdownItem>
                                <DropdownItem className='cursor-pointer' onClick={()=>addItem('58','claim_group_template')}>{IMLocalized('claim_group_template')}</DropdownItem>
                                <DropdownItem className='cursor-pointer' onClick={()=>addItem('48','import_additional_setting')}>{IMLocalized('import_additional_setting')}</DropdownItem>
                            </ul>
                        </div>
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar className="yamm-fw">
                <DropdownToggle nav caret className="text-white font-text">
                    <span className="font-text">{IMLocalized('employee')}</span>
                </DropdownToggle>
                <DropdownMenu className="scrollable-menu" left="true">
                    <div className="yamm-content">
                        <div className="row">
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('employee')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('7','employee_list')}>{IMLocalized('employee_list')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('8','employee_probation_list')}>{IMLocalized('employee_probation_list')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('9','create_employee')}>{IMLocalized('create_employee')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('49','employee_role')}>{IMLocalized('employee_role')}</DropdownItem>
                                <li className="pl-3 mt-2">
                                    <h4 className="title-color1">{IMLocalized('mass_update')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className='cursor-pointer' onClick={()=>addItem('89','employee_mass_self_help_group')}>{IMLocalized('employee_mass_self_help_group')}</DropdownItem>
                                <DropdownItem className='cursor-pointer' onClick={()=>addItem('90','employee_mass_update_profile')}>{IMLocalized('employee_mass_update_profile')}</DropdownItem>
                                <DropdownItem className='cursor-pointer' onClick={()=>addItem('92','employee_mass_update_contract')}>{IMLocalized('employee_mass_update_contract')}</DropdownItem>
                            </ul>
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('schedules')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('11','schedule_list')}>{IMLocalized('schedule_list')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('12','holiday_list')}>{IMLocalized('holiday_list')}</DropdownItem>
                            </ul>
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('template')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('20','contract_template_list')}>{IMLocalized('contract_template_list')}</DropdownItem>
                                
                            </ul>
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('import')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('10','employee_import')}>{IMLocalized('employee_import')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('47','employee_contract_import')}>{IMLocalized('employee_contract_import')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('79','employee_bank_import')}>{IMLocalized('employee_bank_import')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('82','employee_family_import')}>{IMLocalized('employee_family_import')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('83','employee_passport_import')}>{IMLocalized('employee_passport_import')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('84','employee_permit_import')}>{IMLocalized('employee_permit_import')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>window.location.href="/#/wizard/employee"}>{IMLocalized('employee_wizard')}</DropdownItem>
                            </ul>
                        </div>
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>
            {process.env.REACT_APP_PAYROLL === 'true' &&
            <UncontrolledDropdown nav inNavbar className="yamm-fw">
                <DropdownToggle nav caret className="text-white font-text">
                    <span className="font-text">{IMLocalized('payroll')}</span>
                </DropdownToggle>
                <DropdownMenu className="scrollable-menu" right>
                    <div className="yamm-content">
                        <div className="row">
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('payroll')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('15','payroll_list')}>{IMLocalized('payroll_list')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('42','payroll_increment_monthly')}>{IMLocalized('payroll_increment_monthly')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('95','payroll_increment_daily')}>{IMLocalized('payroll_increment_daily')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('88','payroll_increment_list')}>{IMLocalized('payroll_increment_list')}</DropdownItem>
                            </ul>
                        </div>
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>}
            {process.env.REACT_APP_LEAVE === 'true' &&
            <UncontrolledDropdown nav inNavbar className="yamm-fw">
                <DropdownToggle nav caret className="text-white font-text">
                    <span className="font-text">{IMLocalized('leave')}</span>
                </DropdownToggle>
                <DropdownMenu className="scrollable-menu">
                    <div className="yamm-content">
                        <div className="row">
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('employee_leaves')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('24','leave_calendar1')}>{IMLocalized('leave_calendar1')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('26','leave_table')}>{IMLocalized('leave_table')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('46','self_leave')}>{IMLocalized('self_leave')}</DropdownItem>
                            </ul>
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('leave_setup')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('28','leave_list')}>{IMLocalized('leave_list')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('30','leave_scheme_list')}>{IMLocalized('leave_scheme_list')}</DropdownItem>
                            </ul>
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('leave_approval')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('62','leave_approval_list1')}>{IMLocalized('leave_approval_list1')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('64','mass_assign_leave_scheme_and_approval_group')}>{IMLocalized('mass_assign_leave_scheme_and_approval_group')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('71','mass_assign_leave_approval_group')}>{IMLocalized('mass_assign_leave_approval_group')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('76','mass_assign_leave_scheme')}>{IMLocalized('mass_assign_leave_scheme')}</DropdownItem>
                            </ul>
                        </div>
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>}
            {process.env.REACT_APP_CLAIM === 'true' &&
            <UncontrolledDropdown nav inNavbar className="yamm-fw">
                <DropdownToggle nav caret className="text-white font-text">
                    <span className="font-text">{IMLocalized('claim')}</span>
                </DropdownToggle>
                <DropdownMenu className="scrollable-menu" right>
                    <div className="yamm-content">
                        <div className="row">
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('employee_claim')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('34','claim_table')}>{IMLocalized('claim_table')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('54','claim_approval_self')}>{IMLocalized('claim_approval_self')}</DropdownItem>
                            </ul>
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('claim_setup')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('36','claim_list')}>{IMLocalized('claim_list')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('38','claim_scheme_list')} >{IMLocalized('claim_scheme_list')}</DropdownItem>
                            </ul>
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('payout')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('41','pay_summary_list')}>{IMLocalized('pay_summary_list')}</DropdownItem>
                            </ul>
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('claim_approval')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('63','claim_approval_list2')}>{IMLocalized('claim_approval_list2')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('67','mass_assign_claim_scheme_and_approval_group')}>{IMLocalized('mass_assign_claim_scheme_and_approval_group')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('72','mass_assign_claim_approval_group')} >{IMLocalized('mass_assign_claim_approval_group')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('75','mass_assign_claim_scheme')}>{IMLocalized('mass_assign_claim_scheme')}</DropdownItem>
                            </ul>
                        </div>
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>}
            
            <UncontrolledDropdown nav inNavbar className="yamm-fw">
                <DropdownToggle nav caret className="text-white font-text">
                    <span className="font-text">{IMLocalized('report')}</span>
                </DropdownToggle>
                <DropdownMenu className="scrollable-menu" right>
                    <div className="yamm-content">
                        <div className="row">
                        {process.env.REACT_APP_PAYROLL === 'true' &&
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('payroll_report')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('16','monthly_report_consolidated')}>{IMLocalized('monthly_report_consolidated')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('85','monthly_report')}>{IMLocalized('monthly_report')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('17','payroll_variance_by_employee')}>{IMLocalized('payroll_variance_by_employee')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('18','payroll_computation_summary')}>{IMLocalized('payroll_computation_summary')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('19','payroll_computation_headcount')}>{IMLocalized('payroll_computation_headcount')}</DropdownItem>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('81','employee_payslip')}>{IMLocalized('employee_payslip')}</DropdownItem>
                            </ul>
                            }
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('report_generator')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('73','custom_report')}>{IMLocalized('custom_report')}</DropdownItem>

                                <li className="pl-3 mt-2">
                                    <h4 className="title-color1">{IMLocalized('employee_report')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <EmployeeProbationReport/>
                                <EmployeeRoleReport/>
                                <ForeignWorkerHeadCountReport/>
                                <MonthlyHeadCountReport/>
                            </ul>
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('leave_report')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <SummaryReportLeave/>
                                <LeaveListReport/>
                                <LeaveDetailReport/>
                                <LeaveBalanceReport/>
                            </ul>
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('claim_report')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <SummaryReportClaim/>
                                <ClaimListReport/>
                                <ClaimDetailReport/>
                            </ul>
                        </div>
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar className="yamm-fw">
                <DropdownToggle nav caret className="text-white font-text">
                    <span className="font-text">{IMLocalized('history')}</span>
                </DropdownToggle>
                <DropdownMenu className="scrollable-menu" right>
                    <div className="yamm-content">
                        <div className="row">
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('general_history')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('44','general_history')}>{IMLocalized('general_history')}</DropdownItem>
                            </ul>
                            {process.env.REACT_APP_PAYROLL === 'true' &&
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('payroll_history')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('45','payroll_history')}>{IMLocalized('payroll_history')}</DropdownItem>
                            </ul>
                            }
                        </div>
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>
            {/* ENABLE/DISABLE APRAISAL  */}
            <UncontrolledDropdown nav inNavbar className="yamm-fw">
                <DropdownToggle nav caret className="text-white font-text">
                    <span className="font-text">{IMLocalized('appraisal')}</span>
                </DropdownToggle>
                <DropdownMenu className="scrollable-menu" right>
                    <div className="yamm-content">
                        <div className="row">
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('forms')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('68','appraisal_form_list')}>{IMLocalized('appraisal_form_list')}</DropdownItem>
                            </ul>
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('groups')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('69','appraisal_group_list')}>{IMLocalized('appraisal_group_list')}</DropdownItem>
                            </ul>
                            <ul className="col-lg-3 list-unstyled bor-right">
                                <li className="pl-3">
                                    <h4 className="title-color1">{IMLocalized('appraisal')}</h4>
                                </li>
                                <hr className="m-1"/>
                                <DropdownItem className="cursor-pointer" onClick={()=>addItem('70','appraisal')}>{IMLocalized('appraisal')}</DropdownItem>
                            </ul>
                        </div>
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>
            {process.env.REACT_APP_DEBUG &&
            <NavItem className="cursor-pointer">
                <NavLink className="text-white font-text" onClick={()=>addItem('80','Test')}><span className="font-text">Test</span></NavLink>
            </NavItem>}
        </Nav>
    )
}